<template>
  <div>
    <div v-if="userInfo.is_team_master">
      <Notification></Notification>

    </div>
    <div  v-if="userInfo.is_group_master">
      <SeeNotice></SeeNotice>

    </div>
  </div>
</template>

<script>
import Notification from "components/Notification"
import SeeNotice from "components/SeeNotice"
export default {
  name: "notice",
  data(){
    return{
      userInfos: {}
    }
  },
  created() {
    this.userInfos = this.userInfo
  },
  components:{
    SeeNotice,
    Notification
  },
  watch: {
    userInfo(val, oldVal) {
      return val;
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/notice/notice.scss";

$r: 750/16/1rem;
.other {
  width: 521/$r;
  height: 130/$r;
  margin-top: 24/$r;
  background: #F7F7F7;
  font-size: 24/$r;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #333333;
  line-height: 36/$r;
}

:deep(.van-cell) {
  padding: 20/$r 46/$r 14/$r 20/$r;
}
</style>