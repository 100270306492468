<template>
  <!-- 通知管理 -->
  <div class="team-notification">
    <!--  通知管理审核主体开始-->

    <div class="notification">

      <van-list
        v-model:loading="loading"
        :finished="finished"
        :finished-text="noticeInfo.length > 7 ? '没有更多了' : finishedText "
        @load="onLoad">
        <van-tabs v-model:active="active" bind:change="onChange" title-active-color="#FF6B29"
                  title-inactive-color="#4D4D4D"
                  line-width="70" line-height="3" @click="clickTab"
                  class="custom-class">
          <!--      待处理开始-->
          <van-tab title="待处理" class="pending" :name="'false'">
            <van-checkbox-group v-model="checked" ref="checkboxGroup">
                <van-cell-group class="swipe-cell" v-for="(item,index) in noticeInfo" :key="item.id">
                  <van-checkbox :name="item.id" checked-color="#FF6B29" class="checkbox" label-disabled
                                :class="isCheck === item.id ? 'check-acitve' : ''" @click="onCheckbox">
                    <div class="team-con">
                      <div class="team-item">
                        <div class="team-user-info">
                          <div class="apply-title-con">
                            <div class="apply-title">
                              <div class="vertical-center">
                                <div class="invitation-info-con vertical-center" v-if="item.invite.length !== 0">
                                  <img class="invitation-info-img"
                                       :src="item.invite && item.invite.avatar ? item.invite.avatar : 'https://static.helixlife.cn/edu/home/images/avatar.png'" alt="">
                                  <div class="invitation-info-text white-space">
                                    {{ (item.invite && item.invite.nickname) ? item.invite.nickname : '酸谈社群' }}
                                  </div>
                                </div>
                                <div class="exchange flex" v-if="item.invite.length !== 0">
                                  <div>邀请</div>
                                  <img class="exchange-img" src="~assets/images/user/exchange.png" alt="">
                                </div>
                                <div class="invitation-info-con white-space vertical-center">
                                  <img class="invitation-info-img" :src="item.wechat && item.wechat.avatar ? item.wechat.avatar : 'https://static.helixlife.cn/edu/home/images/avatar.png'" alt="">
                                  <div class="invitation-info-text white-space">
                                    {{ (item.wechat && item.wechat.nickname) ? item.wechat.nickname : '酸谈社群' }}
                                  </div>
                                  <div class="join-text white-space">加入{{ item.user_profile && item.user_profile.invite && item.user_profile.invite.group ?
                                      item.user_profile.invite.group.name : "" }}</div>
                                </div>
                              </div>
                            </div>
                            <div class="apply-time">{{ item.updated_at && item.updated_at }}</div>

                          </div>
                        </div>
                        <div class="apply-tip white-space-2" v-if="item.apply_reason">
                          理由：{{ item.apply_reason && item.apply_reason }}
                        </div>
                      </div>
                    </div>
                  </van-checkbox>
                  <div class="footer">
                    <div class="footer-team white-space">
                      <div class="footer-team-text white-space"  @click="changeTeam(index)">{{ item.user_profile && item.user_profile.invite && item.user_profile.invite.group ? item.user_profile.invite.group.name : "未分组" }}</div>
                      <img class="footer-team-img" src="../assets/images/user/notice-more.png" alt="">
                    </div>
                    <div class="footer-btn">
                      <div class="adopt align-center" @click="slideOperation('pass',item.id,item.user_profile.invite.team_group_id)">通过</div> <!-- -->
                      <div class="refuse align-center" @click="slideOperation('rejection', item.id,)">驳回</div>
                    </div>
                  </div>
                </van-cell-group>
            </van-checkbox-group>
          </van-tab>
          <!--      待处理结束-->

          <!--      已处理开始-->
          <van-tab title="已处理" :name="'true'" :class="noticeInfo.length == 0 ? 'pending' : ''">
            <div class="processed">
              <div class="team-con">
                <div class="team-item" v-for="item in noticeInfo" :key="item.id">
                  <div class="team-user-info">
                    <div class="apply-title-con">
                      <div class="apply-title">
                        <div class="vertical-center">
                          <div class="invitation-info-con vertical-center" v-if="item.invite.length !== 0">
                            <img class="invitation-info-img"
                                 :src="item.invite && item.invite.avatar ? item.invite.avatar : 'https://static.helixlife.cn/edu/home/images/avatar.png'" alt="">
                            <div class="invitation-info-text white-space">
                              {{ (item.invite && item.invite.nickname) ? item.invite.nickname : '酸谈社群' }}
                            </div>
                          </div>
                          <div class="exchange flex" v-if="item.invite.length !== 0">
                            <div>邀请</div>
                            <img class="exchange-img" src="~assets/images/user/exchange.png" alt="">
                          </div>
                          <div class="invitation-info-con vertical-center white-space">
                            <img class="invitation-info-img" :src="item.wechat && item.wechat.avatar ? item.wechat.avatar : 'https://static.helixlife.cn/edu/home/images/avatar.png'" alt="">
                            <div class="invitation-info-text white-space">
                              {{ (item.wechat && item.wechat.nickname) ? item.wechat.nickname : '酸谈社群' }}
                            </div>
                            <div class="join-text white-space" v-if="item.handle_status == 'agree'">加入{{ item.team_group && item.team_group.group ?
                                item.team_group.group.name : "" }}</div>
                            <div class="join-text white-space" v-else-if="item.handle_status == 'reject'" >加入{{ item.user_profile && item.user_profile.invite && item.user_profile.invite.group ?
                                item.user_profile.invite.group.name : "" }}</div>
                          </div>
                        </div>
                      </div>
                      <div class="apply-time">{{ item.updated_at && item.updated_at }}</div>

                    </div>
                  </div>
                  <div class="reason" v-if="item.handle_status === 'reject'">
                    <div class="reason-title vertical-center" @click.stop="clickMroe(item.id)">理由
                      <img class="reason-img"
                           :src="require(isShowMore === item.id ? '../assets/images/user/fold-up.png' : '../assets/images/user/fold-down.png')"
                           alt=""></div>
                    <div v-if="item.reject_reason">
                      <div class="apply-tip white-space-2" v-if="isShowMore === item.id">
                        {{ item.reject_reason && item.reject_reason }}
                      </div>
                    </div>
                  </div>
                  <div class="to-examine"
                       :class="item.handle_status && (item.handle_status === 'reject' ? '' :'reject')">
                    {{ item.handle_status && (item.handle_status === 'reject' ? '已驳回' : '已通过') }}
                  </div>
                </div>
              </div>
            </div>
          </van-tab>
          <!--      已处理结束-->
        </van-tabs>
      </van-list>
    </div>
    <!--  通知管理审核主体结束-->

    <!--  批量按钮开始-->
    <div class="safety-height" v-show="noticeInfo.length !== 0">
      <div class="batch-operation" v-if="(active === 'false') || noticeInfo.length == 0">
        <div class="batch-con">
          <div class="batch-select-all" checked-color="#FF6B29" @click="checkAll">
            <van-checkbox v-model="checkboxAll" class="batch-select-icon" checked-color="#FF6B29"
                          :disabled="isDisabled">
              <div class="batch-select-title">全选</div>
            </van-checkbox>
          </div>
          <div class="batch-rejection" @click="clickBtn('rejection')">批量驳回</div>
          <div class="batch-pass" @click="clickBtn('pass')">批量通过</div>
        </div>
        <!--    批量按钮结束-->
      </div>
    </div>
    <!--批量按钮开始-->
    <!--审核驳回提示开始-->
    <van-overlay :show="isRejectShow" z-index="9999">
      <div class="overlay-reject-con align-center">
        <div class="overlay-reject">
          <div class="overlay-reject-head">
            <div></div>
            <div class="overlay-reject-text">确认审核</div>
            <img class="overlay-reject-head-img" src="~assets/images/user/overlay-cancel.png"
                 @click="closeOverla('rejection')"/>
          </div>
          <div class="overlay-reject-weiper">
            <div class="overlay-reject-text">选择以下驳回原因驳回后将无法恢复，确定继续吗？</div>
            <div class="overlay-reject-info">
              <van-radio-group v-model="checkedRadio" class="overlay-reject-radio-group">
                <!--驳回提示选项-->
                <van-radio use-icon-slot :name="item.id" label-class="label-class"
                           class="overlay-reject-radio-group-item" v-for="item in rejectTip" :key="item.id">
                  <template #icon="props">
                    <img class="overlay-reject-icon"
                         :src="checkedRadio === item.id ? require('assets/images/user/overlay-redio2.png') : require('assets/images/user/overlay-redio1.png')"/>
                  </template>
                  <template #default="defaul">
                    <div class="label-class">{{ item.text }}</div>
                  </template>
                </van-radio>
                <van-field
                  v-model="otherMessage"
                  type="textarea"
                  placeholder="请输入其他原因"
                  class="other"
                  v-show="checkedRadio === 3"
                />
                <!--驳回提示选项-->
              </van-radio-group>
            </div>
          </div>
          <div class="overlay-btn">
            <div class="confirm" @click="closeOverla('rejection')">再想想</div>
            <div class="cancel" @click="operation('rejection', ids)">确定</div>
          </div>
        </div>
      </div>
    </van-overlay>
    <!--审核驳回提示结束-->

    <!--确认审核提示开始-->
    <van-overlay :show="isConfirmShow" z-index="9999">
      <div class="overlay-confirmation-audit-con align-center">
        <div class="overlay-confirmation-audit">
          <div class="overlay-confirmation-head">
            <div class="overlay-reject-text">确认审核</div>
            <img class="overlay-confirmation-head-img" src="~assets/images/user/overlay-cancel.png"
                 @click="closeOverla('pass')"/>
          </div>
          <div class="overlay-confirmation-body vertical-center">
            <img class="overlay-confirmation-tip-img" src="~assets/images/user/overlay-tip.png"/>
            <div class="overlay-confirmation-tip-text">一旦通过，无法撤回。是否确认审核通过？</div>
            <div class="overlay-btn">
              <div class="cancel" @click="closeOverla('pass')">取消</div>
              <div class="confirm" @click="operation('pass', ids)">确定</div>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
    <!--确认审核提示结束-->
    <!--选择队伍弹框开始-->
    <van-popup v-model:show="showPopup"
               round
               position="bottom"
               :close-on-click-overlay="false"
               :safe-area-inset-bottom="true"
    >
      <van-picker
        :columns="teamArr"
        @confirm="onConfirm"
        @cancel="cancelPro"
        defaultIndex="0"
        :columns-field-names="customFieldName"
      />
    </van-popup>
    <!--选择队伍弹框结束-->
    <van-toast id="van-toast"/>
  </div>
</template>

<script>
import {reactive} from "vue"
export default {
  name: "Notification",
  data() {
    return {
      checked: [],        //选中的按钮集合
      noticeInfo: [],     //通知信息
      page: 1,             //页码
      checkboxAll: false,  //全选按钮
      active: 'false',     //tab选中状态
      isCheck: false,
      isConfirmShow: false, //是否显示确认审核提示
      isRejectShow: false, //审核驳回提示
      checkedRadio: 0,
      rejectTip: [
        {id: 0, text: '无'},
        {id: 1, text: '理由不充分，请详细说明'},
        {id: 2, text: '频繁进行入队、退队操作'},
        {id: 3, text: '其他'},
      ],
      loading: false,
      finished: false,
      isDisabled: false,   //是否禁用
      ids: '',
      otherMessage: '',
      finishedText: '',
      isShowMore: false,
      showPopup: false,
      teamArr: [],    //小队列表
      teamActive: false,  //选中小队
      customFieldName: {
        text: 'name'
      },
      teamId: 0,    //战队id
      itemIndex: -1, //点击索引
      isshowPor: false,
    }
  },
  created() {
    this.getTaskInfo(this.active)
    this.getTeamTags()
  },
  methods: {
    // 获取小队列表
    getTeamTags(){
      let _this = this
      let url = _this.$api.teamTags
      _this.$http.get(url, true).then(res => {
        if (res.data.success) {
          _this.teamArr = res.data.data
        }
      })
    },
    //获取通知
    getTaskInfo(type) {
      let _this = this
      let url = _this.$api.Teamnot + `?page=${_this.page}&is_deal=${type}`
      _this.finished = true;
      _this.$http.get(url, true).then(res => {
        if (res.data.success) {
          this.loading = false;
          if (res.data.data.data.length) {
            this.finished = false;
            res.data.data.data.map(item => {
              // console.log(item.team_group.group)
              if (item.team_group && item.team_group.group){
                item.team_group.team_group_name = item.team_group.group.name
                /*if(item.team_group.group){
                  item.team_group.group = {
                    id: 0,
                    name: "未分组"
                  }
                }*/
              }
            })
            _this.noticeInfo = _this.noticeInfo.concat(res.data.data.data)
            if (res.data.data.data) {
              _this.isDisabled = false
              _this.checkboxAll = false
              _this.finishedText = ''
            }

          } else {
            this.finished = true;
            if (_this.noticeInfo.length === 0) {
              _this.isDisabled = true
              _this.checkboxAll = false
              _this.finishedText = '暂无数据'

            }
          }
        }
      })
    },
    // 切换tab
    clickTab(value) {
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration:1000
      });
      this.page = 1
      this.checked = []
      this.noticeInfo = []
      this.getTaskInfo(this.active)
    },
    // 选择小队
    changeTeam(index){
      this.showPopup = true
      this.itemIndex = index
      this.isshowPor = true
    },
    //确定操作
    operation(type, ids) {
      let _this = this
      let url = _this.$api.Notifications
      _this.page = 1

      if (type === 'pass') {
        let data = {
          action: 'access',
          source: 'team',
          ids: ids
        }
        _this.$http.post(url, data, true).then(res => {
          if (res.data.success) {
            _this.isCheck = false
            _this.noticeInfo = []
            _this.getTaskInfo(_this.active)
            _this.checked = []
            _this.checkboxAll = false
            _this.isConfirmShow = false
            _this.showPopup = false
          }
        })

      } else if (type === 'rejection') {
        let data = {
          action: 'reject',
          source: 'team',
          remark: _this.rejectTip[this.checkedRadio].text,
          ids: ids
        }
        if (_this.checkedRadio === 3) {
          if (_this.otherMessage.trim() === '') {
            _this.$toast("请输入驳回原因")
          }
          data.remark = _this.otherMessage
        }
        _this.$http.post(url, data, true).then(res => {
          if (res.data.success) {
            _this.isCheck = false
            _this.isRejectShow = false
            _this.noticeInfo = []
            _this.getTaskInfo(_this.active)
            _this.checked = []
            _this.checkboxAll = false
          }
        })
      }
    },
    // 滑块操作
    slideOperation(type, id,teamGroupId = '') {
      this.checkedRadio = 0
      this.ids = [
        { "id":id,
          "group_id": teamGroupId
        },
      ]

      if (type === 'pass') {
        this.isConfirmShow = true

      } else if (type === 'rejection') {
        this.isRejectShow = true
      }
    },
    // 点击全选
    checkAll() {
      const {checkboxGroup} = this.$refs
      if (this.checkboxAll) {
        checkboxGroup.toggleAll(true)
      } else {
        checkboxGroup.toggleAll(false)
      }
    },
    // 点击单选框
    onCheckbox(vale) {
      this.checkboxAll = (this.checked.length === this.noticeInfo.length)
    },
    //点击批量按钮
    clickBtn(type) {
      let _this = this
      _this.checkedRadio = 0

      if (_this.checked.length === 0) {
        _this.$toast('请勾选');
        return
      }
      if (type === 'pass') {
        _this.showPopup = true

      } else if (type === 'rejection') {
        _this.ids = []
        _this.checked.forEach(item => {
          return _this.ids.push(
            { "id": item,
              "group_id": ''
            })
        })
        _this.isRejectShow = true
      }
    },
    onLoad() {
      this.page++;
      this.getTaskInfo(this.active)
    },
    // 取消操作
    closeOverla(type) {
      if (type === 'pass') {
        this.isConfirmShow = false

      } else if (type === 'rejection') {
        this.isRejectShow = false
      }
    },
    //点击失败原因
    clickMroe(id) {
      if (this.isShowMore == id) {
        this.isShowMore = -1
      } else {
        this.isShowMore = id
      }
    },
    // 点击选择小队确认按钮
    onConfirm(value){
      if (this.isshowPor){
        if(this.noticeInfo[this.itemIndex].user_profile !== null){
          this.noticeInfo[this.itemIndex].user_profile.invite.group = value
          this.noticeInfo[this.itemIndex].user_profile.invite.team_group_id = value.id
          reactive(this.noticeInfo)
          this.showPopup = false
        }else {
          this.noticeInfo[this.itemIndex].user_profile.invite = {
            group: {
              id: 0,
              name: "未分组"
            },
            team_group_id: 0
          }
          reactive(this.noticeInfo)
          this.showPopup = false
        }
        this.isshowPor = false
      }else {
        this.isConfirmShow = true
        this.showPopup = false
        // this.ids = this.checked.toString()
        this.ids = []
        this.checked.forEach(item => {
          return this.ids.push(
            { "id": item,
              "group_id": value.id
            })
        })
      }
    },
    // 选择小队取消
    cancelPro(){
      this.showPopup = false
      this.isshowPor = false
    }
  },
}
</script>

<style scoped lang="scss">
@import "~assets/scss/user/notice/notice.scss";

$r: 750/16/1rem;
.other {
  width: 521/$r;
  height: 130/$r;
  margin-top: 24/$r;
  background: #F7F7F7;
  font-size: 24/$r;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #333333;
  line-height: 36/$r;
}

:deep(.van-cell) {
  padding: 20/$r 46/$r 14/$r 20/$r;
}
:deep(.van-picker__confirm){
  color: #FD6A29;
}
</style>