<template>
  <!-- 通知管理 -->
  <div class="team-notification">
    <!--  通知管理审核主体开始-->

    <div class="notification"> <!--:finished-text=-->
      <van-list
        v-model:loading="loading"
        :finished="finished"
        :finished-text="noticeInfo.length > 7 ? '没有更多了' : finishedText "
        @load="onLoad">
        <van-tabs v-model:active="active" bind:change="onChange" title-active-color="#FF6B29"
                  title-inactive-color="#4D4D4D"
                  line-width="70" line-height="3" @click="clickTab"
                  class="custom-class">
          <!--      待处理开始-->
          <van-tab title="待处理" class="pending" :name="'false'">
            <div class="team-part">
              <div class="team-con" v-for="(item,indx) in noticeInfo" :key="indx">
                <div class="team-item">
                  <div class="team-user-info">
                    <div class="apply-title-con">
                      <div class="apply-title">
                        <div class="vertical-center">
                          <div class="invitation-info-con vertical-center"
                               v-if="item.invite.length !== 0">
                            <img class="invitation-info-img"
                                 :src="item.invite && item.invite.avatar ? item.invite.avatar : 'https://static.helixlife.cn/edu/home/images/avatar.png'" alt="">
                            <div class="invitation-info-text white-space">{{
                                (item.invite && item.invite.nickname) ? item.invite.nickname : '酸谈社群'
                              }}
                            </div>
                          </div>
                          <div class="exchange flex" v-if="item.invite.length !== 0">
                            <div>邀请</div>
                            <img class="exchange-img" src="~assets/images/user/exchange.png"
                                 alt="">
                          </div>
                          <div class="invitation-info-con vertical-center white-space">
                            <img class="invitation-info-img" :src="item.wechat && item.wechat.avatar ? item.wechat.avatar : 'https://static.helixlife.cn/edu/home/images/avatar.png'" alt="">
                            <div class="invitation-info-text white-space">{{
                                (item.wechat && item.wechat.nickname) ? item.wechat.nickname : '酸谈社群'
                              }}
                            </div>
                            <div class="join-text white-space">加入{{ item.user_profile && item.user_profile.invite && item.user_profile.invite.group ?
                                item.user_profile.invite.group.name : "" }}</div>
                          </div>
                        </div>
                      </div>
                      <div class="apply-time">{{ item.updated_at && item.updated_at }}</div>

                    </div>

                  </div>
                  <div class="apply-tip white-space-2" v-if="item.apply_reason">
                    理由：{{ item.apply_reason && item.apply_reason }}
                  </div>
                </div>
              </div>
            </div>
          </van-tab>

          <!--      待处理结束-->

          <!--      已处理开始-->
          <van-tab title="已处理" :name="'true'" :class="noticeInfo.length == 0 ? 'pending' : ''">
            <div class="processed">
              <div class="team-con">
                <div class="team-item" v-for="item in noticeInfo" :key="item.id">
                  <div class="team-user-info">
                    <div class="apply-title-con">
                      <div class="apply-title">
                        <div class="vertical-center">
                          <div class="invitation-info-con vertical-center"
                               v-if="item.invite.length !== 0">
                            <img class="invitation-info-img"
                                 :src="item.invite && item.invite.avatar ? item.invite.avatar : 'https://static.helixlife.cn/edu/home/images/avatar.png'" alt="">
                            <div class="invitation-info-text white-space">{{
                                (item.invite && item.invite.nickname) ? item.invite.nickname : '酸谈社群'
                              }}
                            </div>
                          </div>
                          <div class="exchange flex" v-if="item.invite.length !== 0">
                            <div>邀请</div>
                            <img class="exchange-img" src="~assets/images/user/exchange.png"
                                 alt="">
                          </div>
                          <div class="invitation-info-con vertical-center white-space">
                            <img class="invitation-info-img" :src="item.wechat && item.wechat.avatar ? item.wechat.avatar : 'https://static.helixlife.cn/edu/home/images/avatar.png'" alt="">
                            <div class="invitation-info-text white-space">{{
                                (item.wechat && item.wechat.nickname) ? item.wechat.nickname : '酸谈社群'
                              }}
                            </div>
                            <div class="join-text white-space" v-if="item.handle_status == 'agree'">加入{{ item.team_group && item.team_group.group ?
                                item.team_group.group.name : "" }}</div>
                            <div class="join-text white-space" v-else-if="item.handle_status == 'reject'" >加入{{ item.user_profile && item.user_profile.invite && item.user_profile.invite.group ?
                                item.user_profile.invite.group.name : "" }}</div>
                          </div>
                        </div>
                      </div>
                      <div class="apply-time">{{ item.updated_at && item.updated_at }}</div>

                    </div>
                  </div>
                  <div class="reason" v-if="item.handle_status === 'reject'">
                    <div class="reason-title vertical-center" @click.stop="clickMroe(item.id)">理由
                      <img class="reason-img"
                           :src="require(isShowMore === item.id ? '../assets/images/user/fold-up.png' : '../assets/images/user/fold-down.png')"
                           alt=""></div>
                    <div v-if="item.reject_reason">
                      <div class="apply-tip white-space-2" v-if="isShowMore === item.id">
                        {{ item.reject_reason && item.reject_reason }}
                      </div>
                    </div>
                  </div>
                  <div class="to-examine"
                       :class="item.handle_status && (item.handle_status === 'reject' ? '' :'reject')">
                    {{ item.handle_status && (item.handle_status === 'reject' ? '已驳回' : '已通过') }}
                  </div>
                </div>
              </div>
            </div>
          </van-tab>
          <!--      已处理结束-->
        </van-tabs>
      </van-list>
    </div>
    <van-toast id="van-toast"/>
    <!--  通知管理审核主体结束-->
  </div>
</template>

<script>
export default {
  name: "seeNotice",
  data(){
    return {
      noticeInfo: [],     //通知信息
      page: 1,             //页码
      active: 'false',     //tab选中状态
      isCheck: false,
      loading: false,
      finished: false,
      isDisabled: false,   //是否禁用
      ids: '',
      otherMessage: '',
      finishedText: '',
      isShowMore: false,
      show: true
    }
  },
  created() {

    this.getTaskInfo(this.active)
  },
  methods:{
    //获取通知
    getTaskInfo(type) {
      let _this = this
      let url = _this.$api.Teamnot + `?page=${_this.page}&is_deal=${type}`
      _this.finished = true;
      _this.$http.get(url, true).then(res => {
        if (res.data.success) {
          this.loading = false;
          if (res.data.data.data.length) {
            this.loading = false;
            _this.noticeInfo = _this.noticeInfo.concat(res.data.data.data)
            if (res.data.data.data) {
              _this.isDisabled = false
              _this.checkboxAll = false
              _this.finishedText = ''
            }
          } else {
            this.finished = true;
            if (_this.noticeInfo.length === 0) {
              _this.isDisabled = true
              _this.checkboxAll = false
              _this.finishedText = '暂无数据'

            }
          }
        }else {
          console.log(res)
        }
      })
    },
    // 切换tab
    clickTab(value) {
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration:1000
      });
      this.page = 1
      this.checked = []
      this.noticeInfo = []
      this.getTaskInfo(this.active)
    },
    //点击失败原因
    clickMroe(id) {
      if (this.isShowMore == id) {
        this.isShowMore = -1
      } else {
        this.isShowMore = id
      }
    },
    onLoad(){
      this.page++;
      this.getTaskInfo(this.active)
    }
  }
}
</script>

<style scoped lang="scss">
@import "~assets/scss/user/notice/notice.scss";

$r: 750/16/1rem;
.other {
  width: 521/$r;
  height: 130/$r;
  margin-top: 24/$r;
  background: #F7F7F7;
  font-size: 24/$r;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #333333;
  line-height: 36/$r;
}

:deep(.van-cell) {
  padding: 20/$r 46/$r 14/$r 20/$r;
}
</style>